import { CategoryNavigation, NavigationLink } from "@edgetier/client-components";
import { Permission } from "@edgetier/types";
import {
    faAddressBook,
    faCalendar,
    faChartBar,
    faCog,
    faCommentLines,
    faComments,
    faDesktop,
    faEnvelope,
    faFileChartLine,
    faSearch,
    faUsers,
    faUserTag,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { NavigationGroupNotificationCount } from "components-for/navigation/navigation-group-notification-count";
import useShortcutScope from "components-for/shortcuts/use-shortcut-scope";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { SETTINGS_PERMISSIONS } from "constants/permission";
import { AbsolutePaths } from "constants/routes";
import logo from "images/icon-colour-light-background.svg";
import { PageNames } from "types-for/routes";
import { useCurrentUser } from "utilities/use-current-user";
import { ActionsLink } from "./actions-link";
import AgentHomeLink from "./agent-home-link";
import ChatLink from "./chat-link";
import CreateEmailLink from "./create-email-link";
import DeferredQueriesLink from "./deferred-queries-link";
import "./navigation.scss";
import { IProps } from "./navigation.types";
import QueryLink from "./query-link";
import SignOut from "./sign-out/sign-out";
import UsersLink from "./users-link";

/**
 * Display links to other routes. Certain links are only displayed depending on the user's role.
 */
const Navigation = ({
    callFunctionalityEnabled,
    chatFunctionalityEnabled,
    emailFunctionalityEnabled,
    proactiveChatEnabled,
}: IProps) => {
    useShortcutScope();
    const { data: user } = useCurrentUser();

    return (
        <nav className="navigation" aria-label="Main Navigation">
            <div
                className={classNames("navigation__logo", {
                    "navigation__logo--inverted": process.env.NODE_ENV !== "production",
                })}
            >
                <img src={logo} alt="EdgeTier" />
            </div>
            <ul className="navigation__links">
                <VerifyPermission permission={Permission.HandleInteraction}>
                    <AgentHomeLink />
                </VerifyPermission>

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <CreateEmailLink />
                    </VerifyPermission>
                )}

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <QueryLink />
                    </VerifyPermission>
                )}

                {chatFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <ChatLink />
                    </VerifyPermission>
                )}

                {proactiveChatEnabled && user?.isProactiveChatEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <NavigationLink
                            icon={faCommentLines}
                            page={AbsolutePaths[PageNames.ProactiveChat]()}
                            label="Proactive Chat"
                        />
                    </VerifyPermission>
                )}

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <DeferredQueriesLink />
                    </VerifyPermission>
                )}

                <VerifyPermission permission={Permission.ViewReporting}>
                    <CategoryNavigation label="Reporting" icon={faChartBar}>
                        <NavigationLink
                            icon={faUsers}
                            page={AbsolutePaths[PageNames.ReportingAgents]()}
                            label="Active Agents"
                        />
                        {chatFunctionalityEnabled && (
                            <NavigationLink
                                icon={faComments}
                                page={AbsolutePaths[PageNames.ReportingChat]()}
                                label="Chat Queue"
                            />
                        )}
                        {emailFunctionalityEnabled && (
                            <NavigationLink
                                icon={faEnvelope}
                                page={AbsolutePaths[PageNames.ReportingQueue]()}
                                label="Email Queue"
                            />
                        )}
                        <NavigationLink
                            icon={faCalendar}
                            page={AbsolutePaths[PageNames.ReportingHistoric]()}
                            label="Historic"
                        />
                        <NavigationLink
                            icon={faFileChartLine}
                            page={AbsolutePaths[PageNames.ReportingReports]()}
                            label="Reports"
                        />
                        {emailFunctionalityEnabled && (
                            <NavigationLink
                                icon={faDesktop}
                                page={AbsolutePaths[PageNames.ReportingPublicEmail]()}
                                label="Email Wallboard"
                            />
                        )}
                        {chatFunctionalityEnabled && (
                            <NavigationLink
                                icon={faDesktop}
                                page={AbsolutePaths[PageNames.ReportingPublicChat]()}
                                label="Chat Wallboard"
                            />
                        )}
                        {callFunctionalityEnabled && (
                            <NavigationLink
                                icon={faDesktop}
                                page={AbsolutePaths[PageNames.CallWallboard]()}
                                label="Call Wallboard"
                            />
                        )}
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={Permission.ViewSearch}>
                    <CategoryNavigation icon={faSearch} label="Search">
                        <NavigationLink
                            icon={faComments}
                            page={AbsolutePaths[PageNames.SearchInteractions]()}
                            label="Interactions"
                        />
                        <NavigationLink
                            icon={faAddressBook}
                            page={AbsolutePaths[PageNames.SearchQueries]()}
                            label="Queries"
                        />
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={[Permission.ViewAction, Permission.EditAction]}>
                    <ActionsLink />
                </VerifyPermission>

                <VerifyPermission permission={[Permission.EditUser, Permission.EditUserBasic, Permission.EditRole]}>
                    <CategoryNavigation icon={faUsers} label="User Management">
                        <VerifyPermission permission={Permission.EditRole}>
                            <NavigationLink
                                icon={faUserTag}
                                page={AbsolutePaths[PageNames.Permissions]()}
                                label="Permissions"
                            />
                        </VerifyPermission>
                        <VerifyPermission permission={[Permission.EditUser, Permission.EditUserBasic]}>
                            <UsersLink />
                        </VerifyPermission>
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={SETTINGS_PERMISSIONS}>
                    <NavigationLink
                        icon={faCog}
                        label="Settings"
                        page={AbsolutePaths[PageNames.Settings]()}
                        indicator={<NavigationGroupNotificationCount pageNames={[PageNames.SettingsEmailAccounts]} />}
                    />
                </VerifyPermission>

                <SignOut />
            </ul>
        </nav>
    );
};

export default Navigation;
