import { MenuOpenClosedIndicator } from "@edgetier/client-components";
import { doNothing } from "@edgetier/utilities";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { mergeRefs, useLayer } from "react-laag";
import { transformValues } from "./select.utilities";
import { useMemo, useRef, useState, useEffect, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { useSelectSetup } from "./utilities/use-select-setup";

import SelectContext from "./select-context/select-context";
import SelectMenu from "./select-menu";
import {
    APPLY_BUTTON_TEXT,
    CANCEL_BUTTON_TEXT,
    MINIMUM_MULTIPLE_WIDTH,
    MINIMUM_SINGLE_WIDTH,
} from "./select.constants";
import "./select.scss";
import { IProps } from "./select.types";
import { Tooltip } from "@edgetier/components";
import { useSelectPreview } from "./utilities/use-select-preview";
import { useScrollMenuIntoView } from "./utilities/use-scroll-menu-into-view";
import { useIsInputOverflowed } from "./utilities/use-is-input-overflowed";
import { SelectPreviewTooltip } from "./select-preview-tooltip";

/**
 * Custom searchable select component.
 * @param props.addItemMenu                       A react component that will contain the menu to add an item.
 * @param props.children                          Optional function of how to render items.
 * @param props.closeOnDisappear                  Whether the select should close when the trigger is no longer visisble.
 * @param props.description                       Description of the items being displayed.
 * @param props.disableMenuItems                  Whether the select menu items should be disabled or not.
 * @param props.className                         Additional classes to add to the select.
 * @param props.getGroup                          Getter function to the group name from any item.
 * @param props.getGroupOrder                     Getter function to the group order from any item.
 * @param props.getLabel                          Getter function to the label from any item.
 * @param props.getPreviewLabel                   Getter function to the preview label from any item.
 * @param props.getValue                          Getter function to the value from any item.
 * @param props.getIcon                           Getter function to the icon from any item.
 * @param props.hasBorder                         Whether the select should have a border or not.
 * @param props.isAllFilter                       When true, display "all" label for an empty filter.
 * @param props.isClearable                       If the user is allowed remove all values.
 * @param props.isCompact                         Whether the select should be compact or not.
 * @param props.isMedium                          Whether the select should be medium or not.
 * @param props.isDisabled                        Whether the component can be interacted with or not.
 * @param props.isEmptyLabel                      Label to show when no items are selected.
 * @param props.isLoading                         Items loading state.
 * @param props.inputId                           The ID of the input element.
 * @param props.isSearchable                      Whether to show a search box or not.
 * @param props.labelledBy                        The id of the element that labels the select.
 * @param props.isSingleSelect                    When true, only one item can be selected.
 * @param props.isSortedAlready                   Only when false should the items be sorted by label.
 * @param props.isSourcedSelect                   Whether the select requests it's options or gets them passed to it.
 * @param props.items                             Options the user may choose from.
 * @param props.menuShouldScrollIntoView          Whether the menu should scroll into view when it's opened.
 * @param props.matchWidthToField                 Whether the menu should match the width of the field.
 * @param props.minimumWidth                      The minimum width of the menu.
 * @param props.noItemsFoundLabel                 Label to show when no items are found.
 * @param props.onClear                           A function that gets called when the select is cleared.
 * @param props.onClose                           A function that gets called when the select is closed.
 * @param props.onOpen                            Optional callback when the menu is opened.
 * @param props.onInputChange                     A function that gets called when the select's input value changes.
 * @param props.onSelect                          Handle the user selecting item(s).
 * @param props.message                           A message to show when the select is loading.
 * @param props.placeholder                       Search box placeholder text.
 * @param props.previewPlaceholder                Custom content passed to select as placeholder.
 * @param props.selectedValues                    Currently selected values.
 * @param props.useAllItemsSelectedPreviewMessage Where the select preview should say "All items selected" in preview.
 * @param props.placement                         Where the select menu should placed when it's open.
 * @param props.possiblePlacements                The options for where the menu can be placed.
 */
const Select = <IItem extends {}, IValue extends {} = string>({
    addItemMenu,
    children,
    closeOnDisappear = false,
    description,
    disableMenuItems = false,
    className,
    getGroup,
    getGroupOrder,
    getLabel,
    getPreviewLabel = getLabel,
    getValue,
    getIcon,
    isItemDisabled,
    hasBorder = false,
    isAllFilter = false,
    isClearable = false,
    isCompact = false,
    isMedium: initialIsMedium = false,
    isDisabled = false,
    isEmptyLabel,
    isLoading = false,
    inputId,
    isSearchable = true,
    labelledBy,
    isSingleSelect,
    isSortedAlready = false,
    isSourcedSelect = false,
    items: initialItems,
    menuShouldScrollIntoView = false,
    matchWidthToField,
    minimumWidth,
    noItemsFoundLabel,
    onClear: propOnClear = doNothing,
    onClose = doNothing,
    onOpen = doNothing,
    onInputChange,
    onSelect,
    message,
    placeholder = `Search ${description.toLowerCase()}s...`,
    previewPlaceholder,
    selectedValues: initialSelectedValues,
    useAllItemsSelectedPreviewMessage = true,
    placement = "bottom-center",
    possiblePlacements,
    isMultipleSelectCheckbox,
    enableAutoFocus = true,
    ...other
}: IProps<IItem, IValue>) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    // Locked open means that the menu is open and events that usually close it do not. An example use case: when there
    // is a button to add a new select option, it opens a modal and the menu is locked open while the modal is open.
    const [isLockedOpen, setIsLockedOpen] = useState(false);

    // We use Downshift's internal isOpen as a signal of when to open the menu but masterIsOpen has the final say and
    // will be used to close the menu.
    const [masterIsOpen, setMasterIsOpen] = useState(false);

    // Use only items that have a label and maybe them alphabetically.
    const items = useMemo(() => {
        const labelledItems = (Array.isArray(initialItems) ? initialItems : []).filter(
            (item) => typeof getLabel(item) === "string"
        );

        if (isSortedAlready) {
            if (typeof getGroup === "function") {
                return labelledItems.sort((itemOne, itemTwo) => getGroup(itemOne).localeCompare(getGroup(itemTwo)));
            }
            return labelledItems;
        }

        return labelledItems
            .slice()
            .sort((itemOne, itemTwo) => getLabel(itemOne).localeCompare(getLabel(itemTwo)))
            .sort((itemOne, itemTwo) =>
                // Sort by group if any
                typeof getGroup === "function" ? getGroup(itemOne).localeCompare(getGroup(itemTwo)) : 0
            )
            .sort((itemOne, itemTwo) =>
                // Sort by group order if exists
                typeof getGroupOrder === "function" ? getGroupOrder(itemOne) - getGroupOrder(itemTwo) : 0
            );
    }, [getLabel, getGroup, initialItems, isSortedAlready, getGroupOrder]);

    // Make sure the selected values are always an array.
    const selectedValues = transformValues(initialSelectedValues);

    // Get the currently selected items. These are the items that the user has clicked "Apply" for. This variable only
    // changes when the user clicks "Apply". It does not change when an option is selected from the dropdown before
    // clicking "Apply".
    const selectedItems = useMemo(
        () => items.filter((item) => selectedValues.includes(getValue(item))),
        [getValue, items, selectedValues]
    );

    const menuMinimumWidth = Math.max(
        triggerRef.current?.clientWidth ?? 0,
        typeof minimumWidth !== "undefined"
            ? minimumWidth
            : isSingleSelect || isMultipleSelectCheckbox
              ? MINIMUM_SINGLE_WIDTH
              : MINIMUM_MULTIPLE_WIDTH
    );

    const menuMaximumWidth = matchWidthToField ? triggerRef.current?.clientWidth ?? undefined : undefined;

    const clear = () => {
        setInputValue("");
        onSelectItems([], []);
        propOnClear();
    };

    /**
     * Remove all selections.
     */
    const onClear = (mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        clear();
        close();
        // Don't open the menu when this is clicked. Closing is fine though.
        if (!isOpen) {
            mouseEvent.stopPropagation();
        }
    };

    /**
     * Close the menu.
     */
    const close = (options: { blur?: boolean } = { blur: true }) => {
        setInputValue("");
        setMasterIsOpen(false);

        if (typeof options.blur === "undefined" || options.blur) {
            inputRef.current?.blur();
            setIsFocused(false);
        }

        onClose();
    };

    /**
     * Handle a user selected one or more values. If this is a single selection a single item is removed from the array
     * of selected values.
     * @param newSelectedValues Values the user has selected.
     */
    const onSelectItems = (newSelectedValues: IValue[], newSelectedItems: IItem[]) => {
        if (isSingleSelect) {
            onSelect(newSelectedValues[0] ?? null, newSelectedItems[0] ?? null);
        } else {
            onSelect(newSelectedValues, newSelectedItems);
        }
    };

    /**
     * Open or close the menu.
     */
    const onIsOpenChange = useCallback(
        (isOpen: boolean) => {
            if (isDisabled) {
                return;
            }

            if (isOpen) {
                onOpen();
            } else {
                onClose();
            }
        },
        [isDisabled, onClose, onOpen]
    );

    const { comboboxProps, multiplSelectionProps, setInputValue, selectMenuProps, menuContainerRef } = useSelectSetup({
        close,
        disableMenuItems,
        getLabel,
        getValue,
        items,
        inputId,
        selectedValues,
        isMultipleSelectCheckbox,
        isSingleSelect,
        isSourcedSelect,
        isItemDisabled,
        onInputChange,
        clear,
        onSelectItems,
        onIsOpenChange,
    });
    const { isOpen: downshiftIsOpen } = comboboxProps;

    const isOpen = useMemo(() => {
        return isLockedOpen || downshiftIsOpen || masterIsOpen;
    }, [downshiftIsOpen, isLockedOpen, masterIsOpen]);

    const selectPreview = useSelectPreview({
        description,
        getLabel: getPreviewLabel,
        isAllFilter,
        isEmptyLabel,
        searchPlaceholder: placeholder,
        isSingleSelect: isSingleSelect ?? false,
        items,
        isOpen,
        previewPlaceholder,
        selectedItems,
        useAllItemsSelectedPreviewMessage,
    });

    /**
     * Handle the blur event. If the user clicks outside the select, close it. If the user clicks on an item inside the
     * menu, don't close and focus the input field.
     * @param event The blur event.
     */
    const onBlur = (event: React.FocusEvent) => {
        if (isLockedOpen) {
            return;
        }

        const relatedTarget = event.relatedTarget as HTMLElement | null;
        const innerText = relatedTarget?.innerText;

        const hasItemInsideMenuBeenClicked =
            (masterIsOpen && relatedTarget === null) || menuRef.current?.contains(relatedTarget);

        const isApplyOrCancel = innerText === APPLY_BUTTON_TEXT || innerText === CANCEL_BUTTON_TEXT;

        if (hasItemInsideMenuBeenClicked && !isApplyOrCancel) {
            inputRef.current?.focus();
            return;
        }
        if (!isApplyOrCancel) {
            close();
        }
    };

    /**
     * Handle the focus event.
     */
    const onFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const inputProps = comboboxProps.getInputProps(
        multiplSelectionProps.getDropdownProps({
            autoComplete: "off",
            preventKeyAction: true,
            disabled: isDisabled,
            onBlur,
            onFocus,
            "aria-labelledby": labelledBy,
        })
    );

    const { renderLayer, triggerProps, layerProps } = useLayer({
        auto: true,
        isOpen,
        onDisappear: closeOnDisappear ? () => close() : doNothing,
        onOutsideClick: isLockedOpen ? doNothing : close,
        onParentClose: () => close(),
        triggerOffset: 4,
        possiblePlacements,
        placement,
        ResizeObserver,
    });

    const isOverflowed = useIsInputOverflowed(inputRef.current, masterIsOpen);

    useScrollMenuIntoView(menuShouldScrollIntoView, isOpen, inputRef.current);

    // Sometimes, react-laag will give a negative top value in its layerProps.style. Usually when the screen is small.
    // Use a local isMedium state and useEffect to check if top is negative and use the isMedium state to change the size of the menu to fit in the screen.
    const [isMedium, setIsMedium] = useState(initialIsMedium);

    useEffect(() => {
        if (Number(layerProps.style.top) < 0 && !isMedium) {
            setIsMedium(true);
        }
    }, [layerProps.style.top, isMedium]);

    // We use Downshift's internal isOpen to signal when to open the menu but we want to manage the open/close state
    // ourselves once it is open.
    useEffect(() => {
        if (downshiftIsOpen) {
            setMasterIsOpen(true);
        }
    }, [downshiftIsOpen]);

    return (
        <SelectContext.Provider
            value={{
                close,
                lockOpen: () => setIsLockedOpen(true),
                unlock: () => setIsLockedOpen(false),
            }}
        >
            <>
                <Tooltip
                    content={
                        <SelectPreviewTooltip<IItem, IValue>
                            selectedItems={selectedItems}
                            getLabel={getLabel}
                            getValue={getValue}
                            description={description}
                        />
                    }
                    delayEnter={250}
                    disableTooltip={!isOverflowed || selectedItems.length === 0 || isOpen}
                    useArrow
                >
                    <div
                        className={classNames("select__trigger__container", {
                            "select__trigger__container--is-focused": isFocused,
                        })}
                        {...triggerProps}
                        data-testid={other?.["data-testid"]}
                        ref={mergeRefs(triggerProps.ref, triggerRef)}
                    >
                        <input
                            className={classNames("select__trigger", {
                                "select--is-disabled": isDisabled,
                                "select--has-border": hasBorder,
                                "select__trigger--item-selected": selectedItems.length > 0 && !isOpen,
                            })}
                            type="text"
                            {...inputProps}
                            ref={mergeRefs(inputProps.ref, inputRef)}
                            placeholder={selectPreview}
                        />

                        <div className="select__controls">
                            {isClearable && selectedItems.length > 0 && (
                                <div
                                    aria-label="Clear selections"
                                    className="select__control select__clear"
                                    onClick={onClear}
                                    role="button"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            )}
                        </div>
                        <div
                            className="select__toggle-button"
                            aria-label="toggle menu"
                            {...comboboxProps.getToggleButtonProps({ disabled: isDisabled })}
                        >
                            <MenuOpenClosedIndicator isOpen={isOpen} />
                        </div>
                    </div>
                </Tooltip>

                <div className="select__menu__container" ref={menuContainerRef}>
                    {isOpen &&
                        renderLayer(
                            <div
                                className={classNames("select__menu", className, {
                                    "select__menu--is-compact": isCompact,
                                    "select__menu--is-medium": isMedium,
                                    "select__menu--is-multiple-select": isSingleSelect !== true,
                                    "select__menu--is-single-select": isSingleSelect,
                                })}
                                {...layerProps}
                                ref={mergeRefs(layerProps.ref, menuRef)}
                                style={{
                                    ...layerProps.style,
                                    maxWidth: menuMaximumWidth,
                                    minWidth: menuMinimumWidth,
                                }}
                            >
                                <SelectMenu<IItem, IValue>
                                    addItemMenu={addItemMenu}
                                    description={description}
                                    getGroup={getGroup}
                                    isLoading={isLoading}
                                    message={message}
                                    noItemsFoundLabel={noItemsFoundLabel}
                                    onMassSelect={() => setInputValue("")}
                                    isMultipleSelectCheckbox={isMultipleSelectCheckbox}
                                    enableAutoFocus={enableAutoFocus}
                                    isCompact={isCompact}
                                    {...selectMenuProps}
                                    {...other}
                                >
                                    {children}
                                </SelectMenu>
                            </div>
                        )}
                </div>
            </>
        </SelectContext.Provider>
    );
};

export default Select;
